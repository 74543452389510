import { Link } from "@StarberryUtils";
import React, { useEffect, useState } from "react";
import HTMLReactParser from 'html-react-parser';
import $ from 'jquery'
import { navigate } from "@reach/router"
import axios from "axios";

import { GetURL } from "../../common/site/functions";

import PlayVideo from "../../Play/custom-video";
// import BookAViewForm from "../../forms/book-a-viewing-form"
// import ValuationFormPage from "../../forms/valuation-form";
import ModalTiny from "react-bootstrap/Modal"

import { Button, Container, Form, Tabs, Tab } from 'react-bootstrap';
// Images
import Google from "../../../images/google-reviews.png"
// import SearchInputBox from '../../predictive-search/search-inputbox';
// //import Hits from '../../common/site/algolia-hits';
// import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
// import AdvanceSearch from "./AdvanceSearch";
import "./Banner.scss"
import Loadable from "@loadable/component"
import { useMediaQuery } from 'react-responsive'
import { CloseIcon } from "../../Icons/Icons"; 

const BookAViewForm = Loadable(() => import("../../forms/book-a-viewing-form"))
const ValuationFormPage = Loadable(() => import("../../forms/valuation-form"))
const SearchInputBox = Loadable(() => import("../../predictive-search/search-inputbox"))
const AdvanceSearch = Loadable(() => import("./AdvanceSearch"))
const ImageTransform = Loadable(() => import("../../common/ggfx-client/module/components/image-transform"))


// styles
//import ScrollAnimation from "react-animate-on-scroll";

// markup

const Banner = (props) => {

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-device-width: 1224px)'
  })
  const isTabletOrMobileDevice = useMediaQuery({
    query: '(max-device-width: 1224px)'
  })

  let [isPlay, setPlay] = React.useState(false);
  let [reviewCount, setReviewCount] = React.useState(0);
  let [averageRating, setAverageRating] = React.useState(0);
  let [mute, setMute] = React.useState(true);

  let url_1 = '';
  let url_2 = ''
  if (props.cta_1_link) {
    url_1 = GetURL(props.cta_1_link.id)
  }
  if (props.cta_2_link) {
    url_2 = GetURL(props.cta_2_link.id)
  }

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);
  const openBookViewformModal = () => {
    setBookViewingformOpen(true);
  }
  const closeBookViewformModal = () => {
    setBookViewingformOpen(false);
  }

  const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
  const openValuationformModal = () => {
    setValuationformOpen(true);
  }
  const closeValuationformModal = () => {
    setValuationformOpen(false);
  }

  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
  
    window.addEventListener("mousemove", () => {
     
      if (renderComponent === false) {
        LoadVideoUrl()
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        LoadVideoUrl()
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      //scriptInsert()
      if (renderComponent === false) {
        LoadVideoUrl()
        setRenderComponent(true)
      }
    })


    // Property search results
    let url = "/";

    $(".sales_btn").click(function () {
      var searcValue = $(".main-banner-content .mobile-banner-search .tab-pane.active .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();

      if (searcValue !== "") {
        url = "in-" + searcValue + "/"
      } else {
        url = "in-dubai/"
      }
      navigate("/properties/for-sale/" + url);
    })

    $(".rent_btn").click(function () {
      var searcValue = $(".main-banner-content .mobile-banner-search .tab-pane.active .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
      if (searcValue !== "") {
        url = "in-" + searcValue + "/"
      } else {
        url = "in-dubai/"
      }
      navigate("/properties/for-rent/" + url);
    })

    $(".commercial_sales_btn").click(function () {
      var searcValue = $(".main-banner-content .mobile-banner-search .tab-pane.active .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
      if (searcValue !== "") {
        url = "in-" + searcValue + "/"
      } else {
        url = "in-dubai/"
      }
      navigate("/properties/commercial/for-sale/" + url);
    })

    $(".commercial_rent_btn").click(function () {
      var searcValue = $(".main-banner-content .mobile-banner-search .tab-pane.active .searchVal").val().split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
      if (searcValue !== "") {
        url = "in-" + searcValue + "/"
      } else {
        url = "in-dubai/"
      }
      navigate("/properties/commercial/for-rent/" + url);
    })

    $('.react-autosuggest__input').on('click', function () {
      if (props.handleChange) {
        props.handleChange("stop");
      }
    });

    if (props.pagename !== "Home" && props.rating == true) {
      getitems(`${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`);
    }

    // Property search results 
  }, [])


  const LoadVideoUrl = () => {
    if (typeof window !== 'undefined') {
      var video = $('#myvideo');
      var WindowWidth = $(window).width();
      if (WindowWidth > 572) {
        video.append("<source src='" + props.video + "' type='video/mp4' >");
      }
    }
  }

  const wordToNumber = { 'ONE': 1, 'TWO': 2, 'THREE': 3, 'FOUR': 4, 'FIVE': 5 };
  const strapiconfig = {
    headers: {
        Authorization:
            `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
    },
}

  const getitems = async url => {
    try {
      const { data } = await axios.get(url,strapiconfig)// could be from env files 
      console.log(url,"url")
      console.log(data.length,"data")
      setReviewCount(data.length);
      let sumOfScores = 0;
      data.map((review) => {
        const reviewStringValue = review.starRating;
        sumOfScores += wordToNumber[reviewStringValue];
      })
      setAverageRating(Math.round(sumOfScores / data.length * 10) / 10);
    } catch (error) {
      // cache it if fail/error;

    }
  }

  const currentpage = typeof window !== 'undefined' ? window.location.pathname : ''
  if (currentpage == '/contact/') {
    var html = "<h1>Real estate agents in Dubai</h1> <p class='address'>Office 2101 &amp; 2404, Marina Plaza, Dubai Marina, Dubai,</p> <p class='address'>PO Box 171418</p> <p class='address'><strong>Email:&nbsp;</strong><a href='../../../../../contact/message-us/'>maria@vayk.ae</a></p>";
    var contactpagecontent = HTMLReactParser(html);
  } else {
    var contactpagecontent = HTMLReactParser(props.content.replace(/<br\s*\/?>/gi, ''));
  }

  const image_url = props.image.url

  let processedImages = JSON.stringify({});
  if (props.imagetransforms?.Banner_Image_Transforms) {
    processedImages = props.imagetransforms.Banner_Image_Transforms;
  }
  let processedFullImages = JSON.stringify({});
  if (props.imagetransforms?.Full_Banner_Image_Transforms) {
    processedFullImages = props.imagetransforms.Full_Banner_Image_Transforms;
  }



  return (
    <React.Fragment>
      <div className={`main-banner`} id="main-banner">
        {props.image && (props.pagename == "Home" || props.layout === 'Careers_Landing') ?
          <>
            <div className="main-banner-embed background-shadow d-block d-xl-block align-items-xl-stretch dd full-banner ">
              {}
              <video id="myvideo" width="100%" height="100%" autoPlay muted={mute} playsInline loop className="video-banner d-none d-sm-block">

              </video>

              {props.image &&
                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Banner_Image.details" attr={{ alt: `${props.image.alternativeText} - Dacha`, class: 'd-block d-sm-none' }} imagetransformresult={processedImages} id={props.id} loadLazy={false} />
              }
            </div>

            <div className="main-banner-start d-none d-sm-block">
              {props.video &&
                <div className="banner-btn">
                  <strong class="video-btn video-mute-btn" onClick={() => { setMute(!mute) }}><i class={`${(mute) ? 'mute-icon' : 'unmute-icon'} `}></i></strong>
                </div>
              }
            </div>
          </>
          :
          (props.search === "Buy_and_Rent" || props.search === "Buy" || props.search === "Rent" || props.layout === 'Our_Story_Landing') ?
            <>
              <div className="main-banner-embed background-shadow d-none d-xl-block align-items-xl-stretch full-banner">
                {props.image &&
                  <ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Full_Banner_Image.details" attr={{ alt: `${props.image.alternativeText} - Dacha`, class: '' }} imagetransformresult={processedFullImages} id={props.id} />
                }
                {props.video &&
                  <div className="banner-btn">
                    <PlayVideo url={props.video}
                    />
                  </div>
                }
              </div>

              <div className="main-banner-embed background-shadow d-xl-none align-items-xl-stretch dd">
                {props.image &&
                  <ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Banner_Image.details" attr={{ alt: `${props.image.alternativeText} - Dacha`, class: '' }} imagetransformresult={processedImages} id={props.id} />
                }
                {props.video &&
                  <div className="banner-btn">
                    <PlayVideo url={props.video}
                    />
                  </div>
                }
              </div>
            </>
            :
            <div className="main-banner-embed background-shadow d-xl-flex align-items-xl-stretch dd">
              {props.image &&
                <ImageTransform imagesources={image_url} renderer="srcSet" imagename="articles.Banner_Image.details" attr={{ alt: `${props.image.alternativeText} - Dacha`, class: '' }} imagetransformresult={processedImages} id={props.id} />
              }
              {props.video &&
                <div className="banner-btn">
                  <PlayVideo url={props.video}
                  />
                </div>
              }
            </div>
        }


      <div className='main-banner-content'>
          <Container>
            <div className="banner-text">
              {props.content &&
                <>
                  {HTMLReactParser(props.content.replace(/<br\s*\/?>/gi, ''))}
                </>
              }


              {props.search === 'Commercial_Buy' &&
                <div className="banner-search">
                  <Form.Group className="d-flex align-items-center">
                    <Link to="#" className="search-btn"><i className="search-icon"></i></Link>
                    <SearchInputBox type="commercial" />
                    <Button className="d-none d-md-inline-block buy commercial_sales_btn" variant="primary">
                      <span>Buy</span>
                    </Button>
                  </Form.Group>
                  <div className="buttons-group d-md-none">
                    <Button variant="primary commercial_sales_btn">Buy</Button>{' '}
                  </div>
                </div>
              }

              {(props.search === "Buy_and_Rent" || props.search === "Buy" || props.search === "Rent") &&
                <AdvanceSearch type={props.search} />
              }

              {props.search === "Book_Appointment" && props.timings &&
                <>

                  {/* <p class="address" >
                    <Link href="tel:+971 50 531 3003" target="_blank" className="whatsapp">+971 50 531 3003</Link>
                  </p> */}

                </>
              }

              {(props.cta_1 || props.cta_2) &&
                <div className="banner-btns">
                  {props.cta_1 && props.search === "Book_Appointment" &&
                    <button className="btn btn-primary contact-book-btn" onClick={openBookViewformModal}>{props.cta_1}</button>
                  }
                  {props.cta_1 && props.search === "Valuation_Form" &&
                    <button className="btn btn-primary contact-book-btn" onClick={openValuationformModal}>{props.cta_1}</button>
                  }
                  {props.cta_1 && props.search != "Book_Appointment" && props.search != "Valuation_Form" &&
                    <Link className="btn btn-primary" to={`/${url_1}`}>{props.cta_1}</Link>

                  }
                  {props.cta_2 &&
                    <Link className="btn btn-outline btn-link" to={`/${url_2}`}>{props.cta_2}</Link>
                  }
                </div>
              }

              {props.rating === true &&
                <div className="google-rating">
                  <div className="img">
                    <img loading="lazy" src={Google} alt="google-review - Dacha" />
                  </div>
                  <div className="rating-content">
                    <p className="rating">{averageRating}/5 Rating from {reviewCount} Reviews</p>
                    {props.pagename != "Our Client Reviews" &&
                      <Link className="more" to="/about-dacha/customer-testimonials">View more reviews</Link>
                    }

                  </div>
                </div>
              }

              {/* {props.info &&
                <div className="connect">
                  <span className="connect-us">
                    <a className="call-us">
                      <i className="icon-call">
                      </i>
                    </a>
                  </span>
                  <span className="tel">
                    {HTMLReactParser(props.info.replace(/<br\s*\/?>/gi, ''))}
                  </span>
                </div>
              } */}
            </div>
          </Container>
        </div>
      </div>
      <ModalTiny size="md" show={modalBookViewformOpen} onHide={closeBookViewformModal} className="modal-form-wrapper form-viewing-wrapper">
				<ModalTiny.Header>
				<div class="modal-close-btn" onClick={closeValuationformModal}><CloseIcon /></div>
				<ModalTiny.Title>
					<h4>Book an Appointment</h4>
				</ModalTiny.Title>
				</ModalTiny.Header>
				<ModalTiny.Body>
				<BookAViewForm
					property_img={``}
					property_id={''}
					page_url={null}
					property_title={''}
					negotiator={''} />
				</ModalTiny.Body>
			</ModalTiny>    
			<ModalTiny show={modalValuationformOpen} onHide={closeValuationformModal} className="modal-form-wrapper form-valuation-wrapper">      
				<ModalTiny.Header>  
				<div class="modal-close-btn" onClick={closeValuationformModal}><CloseIcon /></div>        
				<ModalTiny.Title>
					<h4>Property Valuation</h4>
					<p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam nec risus nec risus convallis aliquet lipsum.</p>
				</ModalTiny.Title>
				</ModalTiny.Header>
				<ModalTiny.Body>
				<ValuationFormPage />
				</ModalTiny.Body>
			</ModalTiny>
    </React.Fragment >
  )
}

export default Banner
